@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import "./tailwind.dist.css";

/* purgecss start ignore */
body {
  font-family: "Source Sans Pro", sans-serif;
}

.required:after {
  content: "*";
  color: red;
}

.v-collapse-content {
  display: none;
  &.v-collapse-content-end {
    display: initial;
  }
}

.caret {
  position: relative;
  float: right;
  right: 20px;
  top: 6px;
  transform: rotate(180deg);

  &.closed {
    top: 2px;
    transform: rotate(360deg);
  }
}

div[name="top-nav"] {
  color: #7b7b7b;

  ul[name="links"] {
    border-bottom: 1px solid #f5f5f5;
    li {
      font-weight: bold;
      color: #000000;
      cursor: pointer;
    }

    li.active {
      border-bottom: 3px solid #007885;
    }
  }
}

div[name="section"] {
  color: #7b7b7b;

  div[name="section-header"] {
    background-color: #49c0b6;
    cursor: pointer;
  }

  div[name="sub-section-header"] {
    background-color: #fff3ce; /* #FFBF00 */
  }
}

.sweet-modal.is-visible {
  border-radius: 25px;
  width: 440px;
}

.sweet-modal.is-visible .sweet-box-actions .sweet-action-close {
  width: 24px;
  height: 21px;
}
/* purgecss end ignore */
